import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import componentI18n from '@/i18n/componentI18n';
import SvgIcon from '@/components/SvgIcon.vue';
export default defineComponent({
    name: 'UserProfileForm',
    components: {
        SvgIcon
    },
    ...componentI18n('components.user_profile_form'),
    data() {
        return {
            name: '',
            acceptedEmails: false
        };
    },
    computed: {
        ...mapGetters(['session']),
        dirty() {
            return this.session.name !== this.name || this.session.acceptedEmails !== this.acceptedEmails;
        }
    },
    created() {
        this.resetForm();
    },
    methods: {
        ...mapActions(['updateUser']),
        passwordText() {
            return this.session.hasPassword ? '********' : this.$t('no_password');
        },
        resetForm() {
            this.name = this.session.name;
            this.acceptedEmails = this.session.acceptedEmails;
        },
        updateUserProfile() {
            this.updateUser({ name: this.name, acceptedEmails: this.acceptedEmails });
        },
        googleUser() {
            return !this.session.hasPassword && !this.session.temporary;
        }
    }
});
