import { FILE_UPLOAD_CONSTANTS } from '@/constants';
import { captureMessage } from '@sentry/vue';
const allowedExtensions = FILE_UPLOAD_CONSTANTS.ALLOWED_EXTENSIONS;
/**
 * Composable used when uploading an image.
 *
 * It validates the image conforms to our requirements and then tries to upload it.
 */
export async function uploadImage(image, callback) {
    if (!allowedExtensions.includes(image.type)) {
        captureMessage(`Failed avatar upload: Invalid image type (${image.type})`);
        return false;
    }
    if (image.size > FILE_UPLOAD_CONSTANTS.MAX_IMAGE_SIZE) {
        captureMessage(`Failed avatar upload: Image exceeds max size (${image.size})`);
        return false;
    }
    try {
        await callback(image);
        return true;
    }
    catch {
        captureMessage(`Failed avatar upload: Could not upload to s3`);
        return false;
    }
}
